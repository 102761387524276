<template>
  <item-card class="mb-4 bg-white rounded">
    <!-- Menu -->
    <template v-slot:menu>
      <button class="button" @click="triggerDownloadInformation(item)">
        <span>Unduh Informasi</span>
        <fa-icon icon="download"></fa-icon>
      </button>
      <button class="button" @click="triggerCopyCode(itemKey, item)">
        <span>Salin Kode Pendaftaran</span>
        <fa-icon icon="pencil-alt"></fa-icon>
      </button>
      <!-- editable and receiveable when only the related period is active -->
      <button
        v-if="item.schedule.period.is_active"
        class="button"
        @click="triggerShowForm(item)"
      >
        <span>Edit</span>
        <fa-icon icon="pencil-alt"></fa-icon>
      </button>
      <button
        class="button"
        @click="triggerAccept(item)"
        v-if="item.schedule.period.is_active"
      >
        <span>Terima</span>
        <fa-icon icon="user-check"></fa-icon>
      </button>
    </template>

    <section class="">
      <!-- Test Date -->
      <div class="grid grid-cols-6 text-xs uppercase tracking-wide">
        <span class="text-gray-600 col-span-2">tanggal tes</span>
        <span class="col-span-4">{{
          item.schedule.started_at | indonesian_date_format
        }}</span>
      </div>
      <!-- Period Information -->
      <div class="grid grid-cols-6 text-xs uppercase tracking-wide">
        <span class="text-gray-600 col-span-2">periode</span>
        <span class="col-span-4">
          {{ item.schedule.period.name }}
        </span>
      </div>
      <span
        class="inline-block mt-2 px-1 text-white bg-red-500 rounded"
        v-if="!item.schedule.period.is_active"
      >
        Gugur Seleksi
      </span>
      <span class="block text-lg mt-2">{{ item.name }}</span>
      <span class="inline-block mr-2 text-gray-600">{{ item.phone }}</span>
      <a
        class="p-1 px-2 bg-gray-100 rounded"
        target="_blank"
        rel="noopener noreferrer"
        :href="`https://wa.me/${item.phone
          .replace(/^0/, '62')
          .replace(/[\W_]/g, '')}`"
        >Kirim pesan</a
      >
      <textarea
        style="opacity: 0.01; height: 0; position: absolute; z-index: -1"
        :id="`codes-${itemKey}`"
      >
      </textarea>
    </section>
  </item-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemKey: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async triggerDownloadInformation() {
      this.$emit("downloading", { candidate: this.item.id });
      this.hideMenu();
    },
    triggerShowForm(item = null) {
      let payload = null;
      if (item !== null) {
        payload = {
          id: item.id,
          name: item.name,
          gender: item.gender,
          phone: item.phone,
        };
      }
      this.SHOW_INIT_FORM(payload);
      this.hideMenu();
    },
    async triggerAccept(item) {
      let confirmAction = confirm(`Terima ${item.name} sebagai peserta didik?`);

      if (!confirmAction) {
        return;
      }
      await this.acceptCandidate(item);
      this.hideMenu();
    },
    triggerCopyCode(key, item) {
      let text = `Nama: ${item.name}\nKontak: ${item.phone}\n\nKode Peserta Didik:\n${item.id}\n\nKode Pendaftaran:\n${item.schedule.id} `;

      text +=
        "\n\nMohon untuk menjaga kerahasiaan kedua kode di atas. Terima kasih\nSilahkan masukkan kedua kode di atas pada halaman https://sditalmadinahpontianak.sch.id/registration";

      let element = document.getElementById(`codes-${key}`);
      element.value = text;

      element.select();
      document.execCommand("copy");

      this.$toasted.success(
        "Kode Pendaftaran dan Kode Peserta Didik telah disalin"
      );
      this.hideMenu();
    },
    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },
    /** Mutations from candidate store */
    ...mapMutations("candidate", ["SHOW_INIT_FORM", "RESET"]),
    /** Actions from candidate store */
    ...mapActions("candidate", ["acceptCandidate"]),
  },
  name: "CandidateListItem",
  components: {
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
  },
};
</script>